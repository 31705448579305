import Header from '../common/Header';
import { List } from 'antd-mobile';
import Footer from '../common/Footer';
import SczwfwClass from "../config/sczwfwConfig";

export default function Bszn() {
  const qhdm = process.env.REACT_APP_QHDM || "";
  const SczwfwConfig = new SczwfwClass(qhdm);
  const data = SczwfwConfig.getBsznList();
  return (
    <>
      <Header name={'办事指南'} />
      <List header="办事指南事项">
        {data.map((item, index) => (
          <List.Item
            key={index}
            onClick={() => {
              window.location.href = item.url;
            }}
          >
            {item.name}
          </List.Item>
        ))}
      </List>
      <Footer />
    </>
  );
}
