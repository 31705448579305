import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {
  Popup,
  List,
  Grid,
  Button,
  Mask,
  DotLoading,
  SpinLoading,
  Empty,
} from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import { request } from '../../util/request';
import cqzs from '../../img/cqzs.png';
import QRCode from 'qrcode.react';
import { message } from 'antd';
import PdfViewYn from '../../common/PdfViewYn';

interface Dzzz {
  zzlxmc: string;
  zzlxdm: string;
  bdcqzh: string;
  qlr: string;
  zzbh: string;
  zl: string;
  zzmlid: string;
  xzqdm: string;
  lx: string;
}

interface ZsInfo {
  lx: string;
  bdcqzk: string;
  qlr: string;
  gyqk: string;
  zl: string;
  bdcdyh: string;
  qllx: string;
  qlxz: string;
  yt: string;
  mj: string;
  syqx: string;
  qlqtzk: string;
  fj: string;
  bdczmh: string;
  zmqlhsx: string;
  qt: string;
  zzmlid: string;
  code: string;
}

export default function ServiceGuide() {
  const [zsinfoLoading, setZsinfoLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleCloseRight, setVisibleCloseRight] = useState(false);
  const [dzzz, setDzzz] = useState<Dzzz[]>([]);
  const [zsinfo, setZsinfo] = useState<ZsInfo>();
  const [zsPdfVisible, setZsPdfVisible] = useState(false);
  const [filePath, setFilePath] = useState('');
  const [zzmlid, setZzmlid] = useState('');

  //获取DOMAIN_URL
  const baseUrl = process.env.REACT_APP_DOMAIN_URL || window.location.origin;

  useEffect(() => {
    setLoading(true);
    request
      .get<Dzzz[]>('/snQuery/dzzz')
      .then((data) => {
        if (data != null) {
          setDzzz(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // 判断是否是是不是苹果手机
  const isIos = () => {
    return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
  };

  const download = (zzmlid: string) => {
    const url = `${baseUrl}/snels/file/downloadByZzmlid?zzmlid=` + zzmlid;
    // 判断是否是ios设备
    if (isIos()) {
      window.location.href = url;
    } else {
      // 复制链接
      const input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('value', url);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        message.success('复制成功，请在浏览器中打开');
      }
    }
  };

  const queryZs = (item: Dzzz) => {
    setVisibleCloseRight(true);
    request
      .post<ZsInfo>('/snQuery/getEwmByZzmlid', {
        bdch: item.bdcqzh,
        lx: item.lx,
        userid: '123456',
        zl: item.zl,
        zzmlid: item.zzmlid,
      })
      .then((data) => {
        setZsinfo(data);
      })
      .finally(() => {
        setZsinfoLoading(false);
      });
  };

  return (
    <div>
      <Header name={'电子证照'} />
      <Popup
        position="right"
        visible={visibleCloseRight}
        showCloseButton
        onClose={() => {
          setVisibleCloseRight(false);
        }}
      >
        <div style={{ overflowY: 'auto', height: '96vh', paddingBottom: 50 }}>
          <div
            style={{
              marginTop: '8%',
              textAlign: 'center',
              fontSize: 18,
              fontWeight: 'bold',
            }}
          >
            证照信息
          </div>
          {zsinfo?.lx === 'zs' ? (
            <List header="证书详情" mode={'card'}>
              <List.Item>不动产权证号：{zsinfo?.bdcqzk}</List.Item>
              <List.Item>权利人：{zsinfo?.qlr}</List.Item>
              <List.Item>共有情况：{zsinfo?.gyqk}</List.Item>
              <List.Item>坐落：{zsinfo?.zl}</List.Item>
              <List.Item>不动产单元号：{zsinfo?.bdcdyh}</List.Item>
              <List.Item>权利类型：{zsinfo?.qllx}</List.Item>
              <List.Item>权利性质：{zsinfo?.qlxz}</List.Item>
              <List.Item>用途：{zsinfo?.yt}</List.Item>
              <List.Item>面积：{zsinfo?.mj}</List.Item>
              <List.Item>使用期限：{zsinfo?.syqx}</List.Item>
            </List>
          ) : null}
          {zsinfo?.lx === 'zm' ? (
            <List header="证书详情" mode={'card'}>
              <List.Item>不动产权证号：{zsinfo?.bdczmh}</List.Item>
              <List.Item>权利人：{zsinfo?.qlr}</List.Item>
              <List.Item>义务人：{zsinfo?.qlr}</List.Item>
              <List.Item>证明权利或事项：{zsinfo?.zmqlhsx}</List.Item>
              <List.Item>不动产单元号：{zsinfo?.bdcdyh}</List.Item>
              <List.Item>坐落：{zsinfo?.zl}</List.Item>
              <List.Item>其他：{zsinfo?.qt}</List.Item>
              <List.Item>附记：{zsinfo?.fj}</List.Item>
            </List>
          ) : null}
          <div style={{ textAlign: 'center' }}>
            <QRCode value={zzmlid || ''} size={180} fgColor="#000000" />
          </div>
        </div>
      </Popup>
      <div style={{ padding: '0px 6px' }}>
        {dzzz.length === 0 ? (
          <div style={{ textAlign: 'center', paddingTop: '26%' }}>
            {loading ? (
              <span style={{ fontSize: 30 }}>
                <DotLoading color="primary" />
              </span>
            ) : (
              <Empty description="暂无数据" />
            )}
          </div>
        ) : (
          dzzz.map((item, index) => {
            return (
              <Grid columns={3} gap={8} key={index} style={{ marginTop: 6 }}>
                <Grid.Item>
                  <div>
                    <img width={'96%'} src={cqzs} alt={'没有数据'} />
                  </div>
                </Grid.Item>
                <Grid.Item span={2} style={{ fontSize: 12 }}>
                  <div>权证号：{item.bdcqzh}</div>
                  <div>证书编号：{item.zzbh}</div>
                  <div>权利人：{item.qlr}</div>
                  <div>坐落：{item.zl}</div>
                  <div style={{ paddingTop: 20 }}>
                    <Button
                      color="primary"
                      fill="outline"
                      loading={zsinfoLoading}
                      onClick={() => {
                        setZzmlid(item.zzmlid);
                        queryZs(item);
                      }}
                      size={'small'}
                    >
                      证照出示
                    </Button>
                    <Button
                      color="primary"
                      fill="outline"
                      style={{ marginLeft: 6 }}
                      loading={zsinfoLoading}
                      onClick={() => {
                        setFilePath(
                          `${baseUrl}/snels/file/previewByZzmlid?zzmlid=${item.zzmlid}`
                        );
                        setZsPdfVisible(true);
                      }}
                      size={'small'}
                    >
                      证照预览
                    </Button>
                    <Button
                      color="primary"
                      fill="outline"
                      style={{ marginLeft: 6 }}
                      loading={zsinfoLoading}
                      onClick={() => {
                        console.log(item);
                        download(item.zzmlid);
                      }}
                      size={'small'}
                    >
                      {{ isIos } ? '下载' : '复制地址下载'}
                    </Button>
                  </div>
                </Grid.Item>
              </Grid>
            );
          })
        )}
      </div>
      <Popup
        visible={zsPdfVisible}
        onMaskClick={() => {
          setZsPdfVisible(false);
        }}
        bodyStyle={{ height: '90vh' }}
      >
        <PdfViewYn filePath={filePath} />
      </Popup>
      <Mask color="white" visible={zsinfoLoading} />
    </div>
  );
}
