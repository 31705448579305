/**
 * 四川政务服务相关配置
 */

class SczwfwClass {

  private listData: { name: string, url: string }[] = [];
  private qhdm: string = "";

  constructor(qhdm: string) {
    this.qhdm = qhdm;
    this.intConfig();
  }

  /**
   * 初始化配置
   */
  private intConfig() {
    switch (this.qhdm) {
      /* 攀枝花 */
      case "510400000000":
        break;
      /* 遂宁 */
      case "510900000000":
        this.listData = this.getSnBsznList();
        break;
      /* 内江 */
      case "511000000000":
        this.listData = this.getNjBsznList();
        break;
    }
  }

  /**
   * 返回遂宁的办事指南数据
   */
  private getSnBsznList() {
    return [
      {
        "name": "不动产登记资料查询",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=512B0029400001-510900000000-000-12510800MB0M784815-1-00&taskType=20&deptCode=4067971135915237376"
      },
      {
        "name": "不动产权证书、证明的换发 ",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300070-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "不动产权证书、证明的补发 ",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300072-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "转移登记（土地承包经营权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300076-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "变更登记（土地承包经营权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300074-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "注销登记（土地承包经营权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300077-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "首次登记（土地承包经营权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300071-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "房产信息查询",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=512B0129000000-510900000000-000-12510800MB0M784815-1-00&taskType=20&deptCode=4067971135915237376"
      },
      {
        "name": "注销登记（国有农用地使用权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=512B0129000000-510900000000-000-12510800MB0M784815-1-00&taskType=20&deptCode=4067971135915237376"
      },
      {
        "name": "首次登记（国有农用地使用权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300078-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "变更登记（国有农用地使用权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300095-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "转移登记（国有农用地使用权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300097-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "首次登记（集体建设用地使用权及建筑物、构筑物所有权）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300086-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "转移登记（集体建设用地使用权及建筑物、构筑物所有权）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300089-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "注销登记（集体建设用地使用权及建筑物、构筑物所有权）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300090-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "变更登记（集体建设用地使用权及建筑物、构筑物所有权）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300088-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "首次登记（国有建设用地使用权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F1500100016-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "注销登记（国有建设用地使用权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F1500100019-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "转移登记（国有建设用地使用权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F1500100018-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "变更登记（国有建设用地使用权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F1500100017-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "变更登记（抵押权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300087-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "转移登记（抵押权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300091-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "首次登记（抵押权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300101-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "注销登记（抵押权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300092-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "变更登记（国有建设用地使用权及房屋所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300063-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "首次登记（国有建设用地使用权及房屋所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300062-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "注销登记（国有建设用地使用权及房屋所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300065-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "转移登记（国有建设用地使用权及房屋所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300064-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "注销异议登记（异议登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300082-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "设立异议登记（异议登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300080-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "首次登记（宅基地使用权及房屋所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300066-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "变更登记（宅基地使用权及房屋所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300067-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "注销登记（宅基地使用权及房屋所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300069-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "转移登记（宅基地使用权及房屋所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300068-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "注销查封登记（查封登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300075-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "设立查封登记（查封登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300073-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "预告登记变更（预告登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300099-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "预告登记注销（预告登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300093-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "预告登记转移（预告登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300096-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "预告登记设立（预告登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300100-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "变更登记（集体土地所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300058-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "首次登记（集体土地所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300060-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "转移登记（集体土地所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300059-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "注销登记（集体土地所有权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300061-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "依申请更正登记（更正登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300085-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "转移登记（地役权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300084-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "变更登记（地役权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300081-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "首次登记（地役权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300079-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "注销登记（地役权登记）",
        "url": "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510900000000&itemCode=511F0093300094-510900000000-000-12510800MB0M784815-1-00&taskType=7&deptCode=4067971135915237376"
      },
      {
        "name": "商品房分户一件事",
        "url": "https://zxbl.sczwfw.gov.cn/themes/themeService/getNewThServiceDh/4492371955575582720?serviceName=%E5%95%86%E5%93%81%E6%88%BF%E5%88%86%E6%88%B7%E4%B8%80%E4%BB%B6%E4%BA%8B&areaCode=510900000000&themeClassName=more"
      },
      {
        "name": "二手房转移登记及水电气联动过户一件事",
        "url": "https://zxbl.sczwfw.gov.cn/themes/themeService/getNewThServiceDh/4492368383114899456?serviceName=%E4%BA%8C%E6%89%8B%E6%88%BF%E8%BD%AC%E7%A7%BB%E7%99%BB%E8%AE%B0%E5%8F%8A%E6%B0%B4%E7%94%B5%E6%B0%94%E8%81%94%E5%8A%A8%E8%BF%87%E6%88%B7%E4%B8%80%E4%BB%B6%E4%BA%8B&areaCode=510900000000&themeClassName=more"
      },
      {
        "name": "夫妻财产约定过户一件事",
        "url": "https://zxbl.sczwfw.gov.cn/themes/themeService/getNewThServiceDh/4492372593030098944?serviceName=%E5%A4%AB%E5%A6%BB%E8%B4%A2%E4%BA%A7%E7%BA%A6%E5%AE%9A%E8%BF%87%E6%88%B7%E4%B8%80%E4%BB%B6%E4%BA%8B&areaCode=510900000000&themeClassName=more"
      }
    ];
  }

  /**
   * 返回内江的办事指南数据
   */
  private getNjBsznList() {
    return [
      {
        name: "变更登记（抵押权登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300087-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      },
      {
        name: "转移登记（抵押权登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300091-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      },
      {
        name: "首次登记（抵押权登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300101-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      },
      {
        name: "注销登记（抵押权登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300092-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      },
      {
        name: "首次登记（国有建设用地使用权及房屋所有权登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300062-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      },
      {
        name: "转移登记（国有建设用地使用权及房屋所有权登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300064-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      },
      {
        name: "预告登记注销（预告登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300093-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      },
      {
        name: "注销登记（国有建设用地使用权及房屋所有权登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300065-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      },
      {
        name: "预告登记转移（预告登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300096-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      },
      {
        name: "预告登记设立（预告登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300100-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      },
      {
        name: "预告登记变更（预告登记）个人 法人",
        url: "https://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=511000000000&itemCode=511F0093300099-511000000000-000-115109000085105078-2-00&taskType=7&deptCode=115109000085105078"
      }
    ];
  }

  /**
   * 获取办事指南配置
   * @param qhdm
   */
  public getBsznList() {
    return this.listData;
  }

}

export default SczwfwClass;

